import * as React from 'react';
import { Link } from 'react-router-dom';
import { HorizontalHeader, LinkExternalIcon, MoonIcon, TwoColumnLayout, Switch } from 'react-unity';
import './Header.css';
import { logout, authenticatedUser } from '../authentication/authModule';
import usrImage from '../images/user.png';
import CreateFeedbackModal from './Feedback/components/CreateFeedbackModal';
import { AlertBanner } from '../models/interfaces/AlertBanner';
import AlertModal from './common/modals/AlertModal';
import { UserRole } from '../models/enums/UserRole';

interface HeaderProps {
	currentTheme: string;
	onThemeChange: (event: React.ChangeEvent<any>) => void;
 }

interface HeaderState {
	expandedDropdown: boolean;
	createFeedbackModalVisible: boolean;
	alert: AlertBanner;
}

type HeaderDropdown = {
	dropdown: React.RefObject<any>;
	button?: Element;
	expanded: boolean;
};

export default class Header extends React.Component<HeaderProps, HeaderState> {
	azureADDropdown: HeaderDropdown;
	networkDropdown: HeaderDropdown;
	approvalsDropdown: HeaderDropdown;
	helpDropdown: HeaderDropdown;
	FeedbackDropdown: HeaderDropdown;

	constructor(props) {
		super(props);
		this.state = {
			expandedDropdown: false,
			createFeedbackModalVisible: false,
			alert: {
				visible: false,
			}
		};
		this.azureADDropdown = { dropdown: React.createRef(), expanded: false };
		this.networkDropdown = { dropdown: React.createRef(), expanded: false };
		this.approvalsDropdown = { dropdown: React.createRef(), expanded: false };
		this.helpDropdown = { dropdown: React.createRef(), expanded: false };
		this.FeedbackDropdown = { dropdown: React.createRef(), expanded: false };
	}

	componentDidMount = () => {
		document.addEventListener('click', this.handleClick);
		this.azureADDropdown.button = document.getElementById('header-dropdown-AD')?.firstElementChild;
		this.networkDropdown.button = document.getElementById('header-dropdown-network')?.firstElementChild;
		this.approvalsDropdown.button = document.getElementById('header-dropdown-approvals')?.firstElementChild;
		this.helpDropdown.button = document.getElementById('header-dropdown-help')?.firstElementChild;
		this.FeedbackDropdown.button = document.getElementById('header-dropdown-feed')?.firstElementChild;
	};

	handleClick = (e) => {
		this.azureADDropdown.expanded = this.handleDropdownToggle(this.azureADDropdown, e);
		this.networkDropdown.expanded = this.handleDropdownToggle(this.networkDropdown, e);
		this.approvalsDropdown.expanded = this.handleDropdownToggle(this.approvalsDropdown, e);
		this.helpDropdown.expanded = this.handleDropdownToggle(this.helpDropdown, e);
		this.FeedbackDropdown.expanded = this.handleDropdownToggle(this.FeedbackDropdown, e);
		this.setState({ expandedDropdown: this.azureADDropdown.expanded
								|| this.networkDropdown.expanded
								|| this.approvalsDropdown.expanded
								|| this.helpDropdown.expanded
								|| this.FeedbackDropdown.expanded });
	};

	handleDropdownToggle = (headerDropdown: HeaderDropdown, event: any) => {
		if (headerDropdown.dropdown.current
			&& headerDropdown.dropdown.current.contains(event.target)) {
			if (!headerDropdown.button.contains(event.target)) {
				(headerDropdown.button as HTMLAnchorElement).click();
				return  false;
			}
			return !headerDropdown.expanded;
		}
		return false;
	};

	externalLink = (href: string, text: string) => {
		return (
			<HorizontalHeader.Nav.Dropdown.Item
				onClick={
					() => {
						window.open(href, '_blank');
					}
				}
				className="em-u-clickable"
			>
				<span className={this.props.currentTheme === 'default' ? 'navLink-default' : 'navLink-dark'}>{text}</span> &nbsp;
				<LinkExternalIcon size='small' className="em-u-display-inline em-u-display-inline-block" />
			</HorizontalHeader.Nav.Dropdown.Item>
		);
	};

	headerLink = (href: string, text: string) => {
		return (
			<Link to={href}>
				<HorizontalHeader.Nav.Dropdown.Item>
					<span className={this.props.currentTheme === 'default' ? 'navLink-default' : 'navLink-dark'}>
						{text}
					</span>
				</HorizontalHeader.Nav.Dropdown.Item>
			</Link>
		);
	};

	handleSubmittedFeedback = () => {
		this.setState({
			alert: {
				visible: true,
				text: 'Feedback message submitted successfully',
				variant: 'success',
			}
		});
	};

	render() {
		return (
			<>
				<HorizontalHeader
					id="portal-header"
					title={
						<span id="header-title">Cloud Self-Service Portal</span>
					}
					className={this.state.expandedDropdown ? 'em-c-header--large-nav em-u-margin-none em-is-active' : 'em-c-header--large-nav em-u-margin-none'}
					color="main"
				>
					<HorizontalHeader.Nav>
						<Link to="/">
							<HorizontalHeader.Nav.Item active>Home</HorizontalHeader.Nav.Item>
						</Link>
						<Link to="/subscriptions">
							<HorizontalHeader.Nav.Item>Subscriptions</HorizontalHeader.Nav.Item>
						</Link>
						{authenticatedUser.isInRole(UserRole.OrgAdmins) && 
							<Link to="/organizations">
								<HorizontalHeader.Nav.Item>Organizations</HorizontalHeader.Nav.Item>
							</Link>
						}
						<div ref={this.azureADDropdown.dropdown}>
							<HorizontalHeader.Nav.Dropdown
								id="header-dropdown-AD"
								text="Entra ID Services"
							>
								{this.headerLink('/cloudGroups', 'Cloud Groups Management')}
								{this.headerLink('/cloudAccounts', 'Cloud Account Management')}
								{this.headerLink('/adApps', 'Entra ID Applications')}
								{/* {this.headerLink('/ad3rdPartyApps', 'Entra ID 3rd Party Applications')} */}
								{this.headerLink('/tap', 'Temporary Access Password')}
								{this.externalLink('http://goto/b2bbeta', 'Entra ID B2B')}
								{this.externalLink(
									'https://goto/SSOForm',
									'New SAML SSO Consultation'
								)}
								{this.externalLink(
									'http://goto/SSO-Support',
									'Existing SAML SSO Consultation'
								)}
							</HorizontalHeader.Nav.Dropdown>
						</div>
						<div ref={this.networkDropdown.dropdown}>
							<HorizontalHeader.Nav.Dropdown
								id="header-dropdown-network"
								text="Network"
							>
								{this.externalLink('https://goto/POR', 'Port Opening Requests: On-Premises')}
								{this.headerLink('/portOpeningRequests/connectedAzure', 'Port Opening Requests: Connected Azure')}
								{/*this.headerLink('/awsPortOpeningRequest/connectedAWS', 'Port Opening Request: Connected AWS')*/}
								{this.headerLink('/doNotDecryptRequests', 'DPI (Deep Packet Inspection) Exception Requests: Connected Azure')}
								{this.externalLink('https://goto/ddiforms', 'DNS Management')}
								{this.headerLink('/nsg', 'Network Security Groups')}
							</HorizontalHeader.Nav.Dropdown>
						</div>
						<div ref={this.approvalsDropdown.dropdown}>
							<HorizontalHeader.Nav.Dropdown
								id="header-dropdown-approvals"
								text="Approvals"
							>
								{this.headerLink('/approvals?tab=accounts', 'Cloud Accounts')}
								{this.headerLink('/approvals?tab=apps', 'Entra Id Applications')}
								{this.headerLink('/approvals?tab=pors', 'Port Opening Requests (POR)')}
								{this.headerLink('/approvals?tab=dpis', 'Deep Packet Inspection (DPI)')}
								{this.headerLink('/approvals?tab=taps', 'Temporary Access Password (TAP)')}
								
							</HorizontalHeader.Nav.Dropdown>
						</div>					
						<div ref={this.helpDropdown.dropdown}>
							<HorizontalHeader.Nav.Dropdown
								id="header-dropdown-help"
								text="Help"
							>
								<TwoColumnLayout>
									<TwoColumnLayout.Main>
										<HorizontalHeader.Nav.Dropdown.Item className="sublink-header">
											CONSULTING
										</HorizontalHeader.Nav.Dropdown.Item>
										{this.externalLink('https://gotocloud.xom.cloud/request-consultation/?', 'Request cloud consultation')}
									</TwoColumnLayout.Main>
									<TwoColumnLayout.Secondary>
										<HorizontalHeader.Nav.Dropdown.Item className="sublink-header">
											DOCUMENTATION
										</HorizontalHeader.Nav.Dropdown.Item>
										{this.externalLink('https://appwiki.xom.cloud/docs/admin/cloudssp/CloudSelfServicePortal.html', ' Cloud SSP Overview')}
										{this.externalLink('https://portal.xom.cloud/swagger', ' Cloud SSP Swagger')}
										{this.externalLink('https://appwiki.xom.cloud/docs/index.html', 'cloudWiki')}
										{this.externalLink('https://docs.ea.xomsvcs.com/', 'EA Public Docs')}
										{this.externalLink('https://docs.microsoft.com/en-us/', 'Microsoft Documentation')}
										<HorizontalHeader.Nav.Dropdown.Item className="sublink-header">
											LEARNING
										</HorizontalHeader.Nav.Dropdown.Item>
										{this.externalLink('https://ssp.gotocloud.xom.cloud/learning-paths-build/', 'Cloud Learning Paths')}
										{this.externalLink('https://goto/CSOLP', 'Subscription Owner Learning Path')}
									</TwoColumnLayout.Secondary>
								</TwoColumnLayout>
							</HorizontalHeader.Nav.Dropdown>
						</div>
						<div ref={this.FeedbackDropdown.dropdown}>
							<HorizontalHeader.Nav.Dropdown
								id="header-dropdown-feed"
								text="Feedback"
							>
								{this.headerLink('/feedback', 'View Feedbacks')}
								<HorizontalHeader.Nav.Dropdown.Item>
									<span 
										onClick={() => {
											this.setState({
												createFeedbackModalVisible: true
											});
										}}
										className={this.props.currentTheme === 'default' ? 'navLink-default' : 'navLink-dark'}>
										Submit Feedback
									</span>
								</HorizontalHeader.Nav.Dropdown.Item>
							</HorizontalHeader.Nav.Dropdown>
						</div>
					</HorizontalHeader.Nav>
					<HorizontalHeader.GlobalNav>
						<HorizontalHeader.GlobalNav.Item
							onClick={
								() => {
									window.open('https://portal.azure.com/#home', '_blank');
								}
							}
							className="redirect-button"
						>
							<span>Microsoft Azure Portal</span>&nbsp;
							<LinkExternalIcon size='small' className="em-u-display-inline-block" />
						</HorizontalHeader.GlobalNav.Item>
						<HorizontalHeader.GlobalNav.Item
							onClick={
								() => {
									window.open('https://appwiki.xom.cloud/docs/index.html', '_blank');
								}
							}
							className="redirect-button"
						>
							<span>AppWiki</span>&nbsp;
							<LinkExternalIcon size='small' className="em-u-display-inline-block" />
						</HorizontalHeader.GlobalNav.Item>					
						<HorizontalHeader.GlobalNav.Item className='em-c-global-nav__item em-is-aligned-right em-u-padding-bottom-none em-u-padding-right'>
							<Switch
								label="Dark Mode"
								active={!(this.props.currentTheme === 'default')}
								onClick={this.props.onThemeChange}
							/>
							&nbsp;
							<span>
								<MoonIcon size='small' />
							</span>
						</HorizontalHeader.GlobalNav.Item>
						<HorizontalHeader.GlobalNav.Dropdown
							text={authenticatedUser?.displayName}
							img={usrImage}
							className="em-u-padding-bottom-none"
							style={{marginLeft: '0px' }}
						>
							<HorizontalHeader.GlobalNav.Dropdown.Item
								onClick={() => logout()}
								className="em-u-clickable"
							>
								<span>Logout</span>&nbsp;
							</HorizontalHeader.GlobalNav.Dropdown.Item>
						</HorizontalHeader.GlobalNav.Dropdown>
					</HorizontalHeader.GlobalNav>
				</HorizontalHeader>
				<AlertModal
					{...this.state.alert}
					willTimeout
					onClose={() => {
						this.setState({
							alert: {
								visible: false,
							},
						});
					}}
				/>
				<CreateFeedbackModal
					visible={this.state.createFeedbackModalVisible}
					onConfirm={() => {
						this.setState({
							createFeedbackModalVisible: false,
						});
						this.handleSubmittedFeedback();
					}}
					onClose={() => {
						this.setState({
							createFeedbackModalVisible: false,
						});
					}}
				/>
			</>
		);
	}
}